import React from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import { PrimaryButton } from "../Buttons";

const CertificatePopup = ({ isOpen, onClose, viewCert, downloadCert }) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={0}
        p={["39px 5% 50px", "39px 5% 50px", "51px 105px", "51px 105px"]}
        maxWidth="100%"
        m="auto 0 0"
      >
        <Text
          color="#2D7FF9"
          fontSize={16}
          fontWeight={600}
          mb="32px"
          onClick={() => {
            onClose();
            viewCert();
          }}
        >
          View Certificate
        </Text>
        <Text
          color="#2D7FF9"
          fontSize={16}
          fontWeight={600}
          mb="48px"
          onClick={downloadCert}
        >
          Download Certificate
        </Text>
        <PrimaryButton
          label="Cancel"
          onClick={onClose}
        />
      </ModalContent>
    </Modal>
  );
};

export default CertificatePopup;
