import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  HStack,
  VStack,
  Progress,
  useDisclosure,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent
} from "@chakra-ui/react";
import { useHistory } from "react-router";
import { abbreviate_number, download } from "../utils/helpers";
import { usePalette } from "react-palette";
import Docs from "../Views/Startups/startup/DocReader";
import CertificatePopup from "./MobileModals/CertificatePopup";

const RaisedAmount = ({ startup }) => {
  const calculatePercentage = () => {
    return (
      (parseInt(Number(startup.amount_raised)) /
        parseInt(startup.investment_target)) *
      100
    );
  };
  return (
    <>
      <Text color="#4E5457" fontSize={12} letterSpacing="0.05em" mb="5px">
        RAISED
      </Text>
      <Flex justifyContent="space-between" mb="14px">
        <Text color="#4E5457" fontSize={14} fontWeight={700}>
          {Math.floor(calculatePercentage())}% • $
          {abbreviate_number(parseInt(Number(startup.amount_raised)), 0)} of $
          {abbreviate_number(parseInt(startup.investment_target), 0)}
        </Text>
        <HStack
          border="1px solid #B7B7B7"
          padding={1}
          fontSize={10}
          spacing={1}
          rounded={2}
        >
          <Text fontWeight={500}>
            {!startup.investor_count ? 0 : startup.investor_count}
          </Text>
          <Text>INVESTORS</Text>
        </HStack>
      </Flex>
      <Progress
        colorScheme="green"
        size="sm"
        value={calculatePercentage()}
        height="5px"
      />
    </>
  );
};

const Tags = ({ startup }) => {
  return (
    <HStack spacing="10px" textTransform="uppercase">
      <Flex
        h="28px"
        p="0 8px"
        alignItems="center"
        border="0.25px solid #B7B7B7"
        borderRadius="2px"
        color="#4E5457"
        fontSize={10}
      >
        {startup?.category_name}
      </Flex>
      <Flex
        h="28px"
        p="0 8px"
        alignItems="center"
        border="0.25px solid #B7B7B7"
        borderRadius="2px"
        color="#4E5457"
        fontSize={10}
      >
        {startup?.investment_stage}
      </Flex>
    </HStack>
  );
};

const NameDescription = ({ name, description, shortenDescription }) => {
  return (
    <>
      <Text color="#2C2C2C" fontSize={18} fontWeight={700} mb="10px">
        {name}
      </Text>
      <Text color="#4E5457" fontSize={14} mb="10px">
        {shortenDescription ? description?.split(".")[0] : description}
      </Text>
    </>
  );
};

const InvestNumbers = ({ startup }) => {
  return (
    <Box
      p="10px 20px"
      border="0.25px solid #E0E0E0"
      borderRadius="4px"
      display={["none", "none", "none", "block"]}
      mb="20px"
    >
      <HStack justifyContent="space-between">
        <VStack spacing="4px" alignItems="flex-start" width="30%">
          <Text color="#68686C" fontSize={12} lineHeight="15px">
            TARGET
          </Text>
          <Text
            color="#4E5457"
            fontSize={14}
            lineHeight="143.16%"
            fontWeight="500"
          >
            ${abbreviate_number(parseInt(startup.investment_target), 0)}
          </Text>
        </VStack>
        <VStack spacing="4px" alignItems="flex-start" width="70%">
          <Text color="#68686C" fontSize={12} lineHeight="15px">
            INSTRUMENT
          </Text>
          <Text
            color="#4E5457"
            fontSize={14}
            lineHeight="143.16%"
            fontWeight="500"
          >
            {startup.equity}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export const InvestedStartUpCard = ({ startup }) => {
  const {
    isOpen: showCert,
    onOpen: viewCert,
    onClose: closeCert
  } = useDisclosure();
  const {
    isOpen: mobileOpen,
    onOpen: mobileOnOpen,
    onClose: mobileOnClose
  } = useDisclosure();

  const downloadCert = () => {
    download(startup.certificate, `${startup.name} certificate.pdf`)
  }
  return (
    <Box border="0.25px solid #E0E0E0" borderRadius="4px">
      <Flex
        padding="18px 20px"
        borderBottom={"0.25px solid #E0E0E0"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Image
          src={startup.startup_logo}
          height={["32px", "55px", "55px", "55px"]}
        />
        {
          startup.certificate &&
          <Text
            color="#68686C"
            fontSize="14px"
            display={["inline-block", "inline-block", "none", "none"]}
            onClick={mobileOnOpen}
          >
            &bull;&bull;&bull;
          </Text>
        }
        <Box display={["none", "none", "flex", "none"]}>
          <Tags startup={startup} />
        </Box>
      </Flex>
      <Box
        p="23px 20px"
        borderBottom="0.25px solid #E0E0E0"
        display={["none", "none", "none", "block"]}
      >
        <Text color="#2C2C2C" fontSize={18} fontWeight={700} mb="12px">
          {startup.name}
        </Text>
        <Tags startup={startup} />
      </Box>
      <Box p="25px 20px" borderBottom="0.25px solid #E0E0E0">
        <HStack spacing="60px">
          <VStack spacing="4px" alignItems="flex-start">
            <Text color="#68686C" fontSize={14} lineHeight="15px">
              You Invested
            </Text>
            <Text color="#4E5457" fontSize={20} lineHeight="143.16%">
              {abbreviate_number(parseInt(startup.investment_amount), 0)}
            </Text>
          </VStack>
          <VStack spacing="4px" alignItems="flex-start">
            <Text color="#68686C" fontSize={14} lineHeight="15px">
              Equity acquired
            </Text>
            <Text color="#4E5457" fontSize={20} lineHeight="143.16%">
              {startup.equity}
            </Text>
          </VStack>
        </HStack>
      </Box>
      {
        startup.certificate &&
        <>
          <Box p="42px 20px" display={["none", "none", "block", "block"]}>
            <HStack spacing="60px">
              <Text
                color="#2D7FF9"
                fontSize={12}
                fontWeight={500}
                lineHeight="17px"
                cursor="pointer"
                onClick={viewCert}
              >
                View Certificate
              </Text>
              <Text
                color="#2D7FF9"
                fontSize={12}
                fontWeight={500}
                lineHeight="17px"
                cursor="pointer"
                onClick={downloadCert}
              >
                Download Certificate
              </Text>
            </HStack>
          </Box>
          <Modal isOpen={showCert} onClose={closeCert}>
            <ModalOverlay />
            <ModalContent width='90%' maxWidth='1000px'>
              <ModalCloseButton zIndex={10} />
              <Docs url={startup.certificate} fileType="pdf" />
            </ModalContent>
          </Modal>
          <CertificatePopup
            isOpen={mobileOpen}
            onClose={mobileOnClose}
            viewCert={viewCert}
            downloadCert={downloadCert}
          />
        </>
      }
    </Box>
  );
};

export const StartUpListItem = ({ startup }) => {
  const history = useHistory();
  return (
    <Box
      border="0.25px solid #E0E0E0"
      borderRadius="4px"
      _hover={{ boxShadow: "lg" }}
      cursor="pointer"
      onClick={() => history.push(`/dashboard/startups/${startup.startup_id}`)}
    >
      <Flex
        padding="18px 20px"
        borderBottom="0.25px solid #E0E0E0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Image
          src={startup.startup_logo}
          height={["32px", "55px", "55px", "55px"]}
        />
        <Box display={["none", "flex", "flex", "none"]}>
          <Tags startup={startup} />
        </Box>
      </Flex>
      <Box p="20px">
        <Box mb="15px" display={["none", "none", "none", "block"]}>
          <NameDescription
            name={startup.name}
            description={startup.description}
            shortenDescription={true}
          />
        </Box>
        <InvestNumbers startup={startup} />
        <RaisedAmount startup={startup} />
        <Box mt="20px" display={["none", "none", "none", "flex"]}>
          <Tags startup={startup} />
        </Box>
      </Box>
    </Box>
  );
};

export const StartupPageCard = ({ startup, limited_access }) => {
  const { data } = usePalette(startup.startup_logo);
  const history = useHistory();
  return (
    <Box border="0.25px solid #E0E0E0" borderRadius="4px" mb="20px">
      <Box
        position="relative"
        height={["50px", "50px", "69px", "109px"]}
        background={data.vibrant}
        borderRadius="4px 4px 0px 0px"
      >
        <Box
          position="absolute"
          width={["48px", "68px", "68px", "88px"]}
          height={["48px", "68px", "68px", "88px"]}
          background="#FFFFFF"
          border="0.25px solid #E0E0E0"
          borderRadius="4px"
          left="20px"
          bottom={["-17px", "-29px", "-29px", "-29px"]}
          p={["5px", "5px", "5px", "14px"]}
        >
          <Image
            src={startup.startup_logo}
            boxSize="100%"
            objectFit="contain"
          />
        </Box>
      </Box>
      <Box p="39px 20px 26px" display={["none", "none", "none", "block"]}>
        <Box mb="15px">
          <NameDescription
            name={startup.name}
            description={startup.description}
            shortenDescription
          />
        </Box>
        <InvestNumbers startup={startup} />
        <RaisedAmount startup={startup} />
        <Box mt="20px">
          <Tags startup={startup} />
        </Box>
      </Box>
      {!limited_access && (
        <Flex
          p={["25px 16px 12px", "20px", "20px", "20px"]}
          pt={["25px", "30px", "30px", "20px"]}
          borderTop="0.25px solid #E0E0E0"
          alignItems="center"
          justifyContent={[
            "space-between",
            "space-between",
            "space-between",
            "center",
          ]}
        >
          <Text
            fontWeight="400"
            color="#2D7FF9"
            fontSize={[12, 12, 12, 16]}
            cursor="pointer"
            onClick={() =>
              history.push(`/dashboard/startups/invest/${startup.id}`)
            }
          >
            Invest{" "}
            <Box as="span" display={["none", "none", "inline", "inline"]}>
              in {startup.legal_name}
            </Box>
          </Text>
        </Flex>
      )}
    </Box>
  );
};
