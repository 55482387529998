import React from "react";
import { useParams } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Box, Container } from "@chakra-ui/react";

import "./style.scss";

const Docs = ({ url, fileType }) => {
  const { base, name, ext } = useParams();
  const uri = url || `https://${base}/${encodeURI(name)}`;
  const docs = [
    {
      uri,
      fileType: fileType || ext,
    },
  ];
  return (
    <Box
      background="#FAFBFD"
      paddingY={["20px 0 100px", "24px"]}
      width="100%"
    >
      <Container height="100%" maxWidth="7xl" alignItems="center">
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          style={{ minHeight: "85vh" }}
        />
      </Container>
    </Box>
  );
};

export default Docs;
